@use '@angular/material' as mat;
@import "@angular/material/theming";
@include mat.core();
//@import "libs";

// Material colors
$mat-colors: (
  red: mat.$red-palette,
  pink: mat.$pink-palette,
  purple: mat.$purple-palette,
  deep-purple: mat.$deep-purple-palette,
  indigo: mat.$indigo-palette,
  #006ce6: mat.$blue-palette,
  light-blue: mat.$light-blue-palette,
  cyan: mat.$cyan-palette,
  teal: mat.$teal-palette,
  green: mat.$green-palette,
  light-green: mat.$light-green-palette,
  lime: mat.$lime-palette,
  yellow: mat.$yellow-palette,
  amber: mat.$amber-palette,
  orange: mat.$orange-palette,
  deep-orange: mat.$deep-orange-palette,
  brown: mat.$brown-palette,
  grey: mat.$grey-palette,
  blue-grey: mat.$blue-grey-palette
);

// Colors shorthand
$ui-colors: (
  black: (
    base: black
  ),
  grey: (
    base: rgba(119, 119, 119, 1),
    boulder: rgba(117, 117, 117, 1),
    slate: rgba(122, 139, 147, 1),
    emperor: rgba(81, 70, 73, 1),
    gallery: rgba(238, 238, 238, 1),
    mercury: rgba(229, 229, 229, 1),
    concrete: rgba(242, 242, 242, 1),
    alabaster: rgba(250, 250, 250, 1)
  ),
  white: (
    base: white
  )
);
@mixin costomize-components($theme) {
  // Apply theme to ngx-datatable componenet
  // @include ngx-mat-data-table($theme);
}
/* *
 * Colors */
// Typography
$mat-typography: mat.define-typography-config(
  $font-family: "Roboto",
  $headline: mat.define-typography-level(24px, 48px, 400),
  $body-1: mat.define-typography-level(16px, 24px, 400)
);
// @include angular-material-typography($mat-typography);
/* * Material Themes */
// default Theme
$agero-primary: mat.define-palette(mat.$blue-palette, 700, 100, 800);
$agero-accent: mat.define-palette(mat.$orange-palette, 700, 100, 800);
$agero-warn: mat.define-palette(mat.$red-palette);

$agero-theme: mat.define-light-theme($agero-primary, $agero-accent, $agero-warn);
@include mat.all-component-themes($agero-theme);
.default-theme {
  @include mat.all-component-themes($agero-theme);
  @include costomize-components($agero-theme);
}
// Dark theme
$dark-primary: mat.define-palette(mat.$blue-grey-palette);
$dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$deep-orange-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
.dark-theme {
  @include mat.all-component-themes($dark-theme);
  @include costomize-components($dark-theme);
}
// Light theme
$light-primary: mat.define-palette(mat.$grey-palette, 200, 500, 300);
$light-accent: mat.define-palette(mat.$brown-palette, 200);
$light-warn: mat.define-palette(mat.$deep-orange-palette, 200);
$light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
.light-theme {
  @include mat.all-component-themes($light-theme);
  @include costomize-components($light-theme);
}
