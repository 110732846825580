$black-color: #000000;
$white-color: #ffffff;
$red-color: #eb0000;
$quinary-color: #181f2c;
$primary-color: #006ce6;
$secondary-color: #c0c0c0;
$tertiary-color: #5a5c5f;
$quaternary-color: #e5e7e8;
$gray-color: #767676;
$light-gray-color: #f0f0f0;
$dark-gray-color: #556471;
$dark-blue-color: #0000ff;
$dark-green-color: #168840;

.text-red {
  color: $red-color !important;
}

.bg-red {
  background-color: $red-color !important;
}

.text-blue {
  color: $primary-color !important;
}

.text-gray {
  color: $gray-color !important;
}

.text-black {
  color: $black-color !important;
}

.text-white {
  color: $white-color !important;
}

.text-dark-blue {
  color: $dark-blue-color !important;
}

.text-transparent {
  color: transparent !important;
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: $white-color !important;
}

.bg-blue {
  background-color: $primary-color !important;
}

.bg-light-gray {
  background-color: $light-gray-color !important;
}

.bg-dark-gray {
  background-color: $dark-gray-color !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;

  &--bold {
    font-weight: bold;
  }
}

.text-pre-line {
  white-space: pre-line !important;
}

.text-word-break {
  word-break: break-all !important;
}

$font-family: Roboto;

@mixin base-control {
  font-size: 14px;
  height: 28px;
  line-height: 16px;
  margin: 0;
  font-weight: 500;
  color: $quinary-color;
}

// Input styles
@mixin abm-input {
  color: $quinary-color;
  border: 1px solid $quaternary-color;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

@mixin abm-border($width: 2px, $color: $black-color) {
  border: $width solid $color;
}

.abm-textarea,
.abm-textbox__primary {
  @include base-control;
  @include abm-input;
  height: auto;
  width: auto;
  font-weight: 400;
}

.ab-e911 {
  color: $red-color;
  background-color: $red-color;
}

.message-data {
  text-align: center;
  color: $red-color;
}

.abm-textarea:disabled,
.abm-textbox__primary:disabled {
  background-color: #e5e7e8;
  color: #7a7a7a;
  cursor: default;
}

.abm-p__readonly {
  @include base-control;
}

// radio button
.abm-radio {
  &__label {
    color: $black-color !important;
  }
}

//script
.abm-script {
  border: 2px solid black;
  border-radius: 0.25rem;
  margin: 1rem;
  padding: 1rem;
}

// Button styles
@mixin abm-button {
  @include base-control;
  letter-spacing: -0.03px;
  padding: 0 8px;
  border-radius: 0.25rem;
  border: 0 none transparent;
  background-color: $primary-color;
  color: $white-color;
}

.abm-button__primary {
  @include abm-button;
}

.abm-button__secondary {
  @include abm-button;
  background-color: $tertiary-color;
}

.abm-button__primary:disabled {
  background-color: #006ce680;
  color: #ffffff80;
  cursor: default;
}

.abm-button__secondary:disabled {
  background-color: $tertiary-color;
  color: #ffffff80;
  cursor: default;
}

.btn-primary {
  height: 2rem;
  line-height: 2rem;
  font-weight: 500;
  padding: 0 1em;
  border-radius: 0.25rem;
  border: 0 none transparent;
  background-color: $primary-color;
  color: $white-color;
  min-width: 8rem !important;

  &:hover,
  :active {
    background-color: #0257b7;
  }

  &:disabled {
    background-color: #006ce680;
    color: #ffffff80;
    cursor: default;
  }
}

.btn-secondary {
  height: 2rem;
  line-height: 2rem;
  font-weight: 400;
  padding: 0 1em;
  border-radius: 0.25rem;
  border: 1px solid $gray-color;
  color: $black-color;
  background-color: $white-color;
  min-width: 8rem !important;

  &:hover,
  :active {
    background-color: #eeeeee;
  }

  &:disabled {
    background-color: $secondary-color;
    cursor: default;
  }
}

.btn-transparent {
  background-color: transparent;
  border: none;
  outline: none;
}

@mixin alignRight {
  float: right;
}

.section-right {
  @include alignRight;
}

/** User availability screen */
@mixin user-status-dropdown {
  font-size: 1rem;
  font-weight: 500;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1em;
  border-radius: 0.25rem;
  border: 0 none transparent;
  color: white;
}

select.online-user-status {
  @include user-status-dropdown;
  background-color: #009e22;
}

select.online-user-status:hover,
select.online-user-status.active,
select.online-user-status:active {
  background-color: #07c931;
}

select.offline-user-status {
  @include user-status-dropdown;
  background-color: #c91e1e;
}

select.offline-user-status:hover,
select.offline-user-status.active,
select.offline-user-status:active {
  background-color: #f31010;
}

select>option.no-color-options {
  background-color: white;
  color: black;
}

.empty-container {
  border: 1px black solid;
  height: 50rem;
  width: 40%;
}

@mixin display-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.flex {
  @include display-flex;
}

.flex-wrap {
  @include display-flex;
  flex-wrap: wrap;
}

.flex-container {
  @include display-flex;
  justify-content: space-evenly;
}

.content-right {
  justify-content: flex-end;
}

.content-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justifty-center {
  justify-content: center;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-gap {
  gap: 1rem;
}

.flex-gap--small {
  gap: 0.5rem;
}

.flex-gap--big {
  gap: 2rem;
}

.flex-gap--xbig {
  gap: 3rem;
}

.flex-basis-auto {
  flex-basis: auto !important;
}

.flex-auto {
  flex: auto;
}

.flex-g-s__1 {
  flex: 1;
}

.flex-g-s__2 {
  flex: 2;
}

.flex-item-1 {
  @extend .flex-g-s__1;
}

.flex-basis-50 {
  flex: 1 1 50% !important;
}

.flex-basis-1_4 {
  flex: 1 1 21% !important;
}

.mt--small {
  margin-top: 0.5rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-auto {
  margin-right: auto;
}

.mb--small {
  margin-bottom: 0.5rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

// margin-left
.ml--xs {
  margin-left: 0.25rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mx-0 {
  @extend .ml-0;
  @extend .mr-0;
}

.mx-1 {
  @extend .ml-1;
  @extend .mr-1;
}

.mx-5 {
  margin-left: 5vw !important;
  margin-right: 5vw !important;
}

.my-0 {
  @extend .mt-0;
  @extend .mb-0;
}

.my-1 {
  @extend .mt-1;
  @extend .mb-1;
}

.my--small {
  @extend .mt--small;
  @extend .mb--small;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.p--small {
  padding: 0.25rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.pl--small {
  padding-left: 0.5rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr--small {
  padding-right: 0.5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.px--small {
  @extend .pl--small;
  @extend .pr--small;
}

.px-0 {
  @extend .pl-0;
  @extend .pr-0;
}

.px-1 {
  @extend .pl-1;
  @extend .pr-1;
}

.pt--small {
  padding-top: 0.5rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb--small {
  padding-bottom: 0.5rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.py--x-small {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py--small {
  @extend .pt--small;
  @extend .pb--small;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.min-width-8 {
  min-width: 8vw !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.mat-icon--inline {
  vertical-align: middle;
  font-size: 20px;
}

// Pdial Control
.mat-icon__white {
  color: $white-color;
  padding-right: 5px;
}

// positions
.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;

  &-right {
    right: 0;
  }

  &-bottom {
    bottom: 0;
  }
}

.pos-fixed {
  position: fixed;
}

.input-mat-icon-right {
  @extend .pos-relative;
  right: 2rem;
}

// mat dialog
.scrollable-content {
  max-height: 80vh;
  overflow-y: auto;
}

.min-width-12 {
  min-width: 12rem !important;
}

.min-height-12 {
  min-height: 12rem !important;
}

.max-width-fit {
  max-width: fit-content;
}

// form field
.form-field {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 32px;

  &__label {
    display: block;
    margin-bottom: 8px;
  }

  &__control {
    display: block;
  }

  &__error {
    color: $red-color;
    font-size: 12px;
    padding: 4px 0;
    min-height: 22px;

    span {
      display: block;
    }
  }

  &__inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 6px;

    >.form-field__label {
      // padding: 0px 12px 0px 0;
      width: 36%;
    }

    >.form-field__control {
      width: 60%;
    }
  }

  &__req {
    color: $red-color;
  }
}

.summary-section {
  position: absolute;
  max-height: 400px;
  max-width: 400px;
  background-color: $quaternary-color;
  margin-left: 20px;
  margin-bottom: 10px;
}

.radio {
  display: inline-block;
  position: relative;
  text-indent: 28px;
  padding: 4px;
  cursor: pointer;

  &__input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked {
      &~.radio__checkmark {
        background-color: $primary-color;
      }
    }
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $secondary-color;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:before {
      display: block;
      background: #fff;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 6px;
      top: 6px;
    }

    &:after {
      display: block;
      background: #fff;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 6px;
      top: 6px;
    }
  }

  &__disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &:hover {
    .radio__checkmark {
      background-color: $quaternary-color;
    }

    .radio__input:checked~.radio__checkmark {
      background-color: #004ba0;
    }
  }
}

input:focus-visible+.radio__checkmark {
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  @include abm-border(1px);
}

.no-border:focus,
.no-border:focus-visible {
  outline: none;
}

// script
.script-box {
  display: inline-flex !important;
  box-sizing: border-box;
  min-height: 43px;
  max-width: 95%;
  border: 1px solid #979797;
  border-radius: 4px;
  background-color: #f2f2f2;
  padding: 16px;

  .script-icon {
    padding-right: 8px;
  }

  .script-info {
    color: #5a5c5f;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 19px;
  }
}

//callback toggle
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.switch-slider {
  background-color: #006ce6;
}

input:focus+.switch-slider {
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  @include abm-border;
}

input:checked+.switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch-slider.round {
  border-radius: 20px;
}

.switch-slider.round:before {
  border-radius: 50%;
}

.section-activity {
  width: 55%;
  height: fit-content;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.section-question {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: -0.03px;
  color: $black-color;
}

.section-detail {
  width: 30%;
  margin-top: -15px;
}

.panel-title {
  font-size: 17px;
  font-weight: 500;
  height: 20px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: $black-color;
}

.summary-questionnaire {
  // width: 75%;
}

.summary-content {
  top: 0vh;
  width: 20%;
  padding-bottom: 0px;
  max-height: 80vh;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  overflow: scroll;
  margin-left: 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.a-cancel {
  text-decoration: none;
  font-weight: 500;
}

.a-cancel:hover {
  text-decoration: none;
}

@mixin abm-button-login {
  width: 85%;
  color: $white-color;
  letter-spacing: 0.4px;
  line-height: 3rem;
  border: 0 none transparent;
  border-radius: 0.25rem;
}


.abm-button {
  &__ready {
    @include abm-button-login;
    background-color: $dark-green-color;

    &:disabled {
      background-color: $gray-color;
      color: $light-gray-color;
      cursor: not-allowed;
    }
  }

  &__done {
    @include abm-button-login;
    background-color: $red-color;

    &:disabled {
      background-color: $gray-color;
      color: $light-gray-color;
      cursor: not-allowed;
    }
  }
}

.banner__outline {
  border-radius: 4px;
  background: #eaf8fc;
}

.banner-typography {
  &-body {
    color: #565656;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
}

@mixin activity-button {
  padding: 0rem 1rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 2.25rem;
  letter-spacing: 0.2px;
  border: 0 none transparent;
  border-radius: 0.25 rem;
  text-transform: uppercase;
}

.button-submit {
  &-next {
    @include activity-button;
    background-color: $dark-green-color;
    color: $white-color;

    &:disabled {
      background-color: $gray-color;
      color: $light-gray-color;
      cursor: not-allowed;
    }
  }

  &-exit {
    @include activity-button;
    background-color: $red-color;
    color: $white-color;

    &:disabled {
      background-color: $gray-color;
      color: $light-gray-color;
      cursor: not-allowed;
    }
  }
}

.activity-home-panel {
  padding: 2rem;
  border-radius: 2px;
  border: 0px solid #8d8d8d;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  width: calc(12vw + 4rem);
}


.error-panel {
  margin: 0;
  padding: 2rem;
  border-radius: 4px;
  width: fit-content;
  background: #FFF8ED;
  outline: #d5d5d6 solid 1px;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
}

.mat-icon__warning {
  color: #e88f21;
  vertical-align: bottom;
}


.question-error-panel {
  margin: 0px;
  padding: 5px;
  color: #272729;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  width: fit-content;
  background: #FFF0EE;
  outline: #d5d5d6 solid 1px;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
}


.mat-icon__error {
  color: #ff695b;
  vertical-align: bottom;
}

.abm-outline {
  font-size: 1rem;
  height: 2rem;
  line-height: 2rem;
  margin: 2px 0px !important;
  padding: 0 0.5rem;
  color: #181f2c;
  border: 1px solid #e5e7e8;
  border-radius: 0.25rem;
  box-sizing: border-box;
  max-width: 175px;
  background-color: #f2f2f2;
  white-space: nowrap;
  overflow: hidden;
}

.abm-collapse-panel {
  min-height: 3rem;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  border: 1px solid #acacac;
  background-color: #f0f0f0;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
}

.fs-1 {
  font-size: 15px !important;
}

.fs--small {
  font-size: 0.79rem !important;
}

.fs--mid {
  font-size: 0.9rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs--big {
  font-size: 2rem !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.f--italic {
  font-style: italic;
}

.f--opacity {
  opacity: 0.75;
}

.am-dialog-title {
  @extend .mb--small;
}

.am-dialog-actions {
  @extend .px--small;
  @extend .py--small;
  margin-top: 1rem;
}


// inline form-control
.inline-form-group {
  @include display-flex;
  @extend .align-center;
  @extend .flex-gap--small;
  @extend .content-between;

  label {
    flex: 0 0 40%;
    white-space: nowrap;

    &.small-label {
      flex-basis: 20% !important;
    }
  }

  span.small-label {
    flex-basis: 20% !important;
    // white-space: nowrap;
  }

  .control {
    @extend .flex-item-1;
    @extend .px--small;
    @extend .py--small;
  }

  .control-info {
    @extend .flex;
    @extend .flex-column;
    @extend .py--small;
  }
}

app-field-view {
  &:nth-child(odd) {
    &>.abm-form-view {
      background-color: #f0f0f0;
    }
  }

  .abm-form-view {
    @include display-flex;
    @extend .align-center;
    @extend .flex-gap--small;
    @extend .content-between;

    >.abm-field-title {
      flex: 0 0 60%;
      @extend .fw-500;
    }

    >.abm-field-value {
      @extend .flex-item-1;
      @extend .px--small;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }
}

.form-view {
  @include display-flex;
  @extend .align-center;
  @extend .flex-gap--small;
  @extend .content-between;

  &:nth-child(odd) {
    background-color: #f0f0f0;
  }

  .field-title {
    flex: 0 0 60%;
  }

  .field-value {
    @extend .flex-item-1;
    @extend .px--small;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// inline form-control
.flex-form-control {
  @include display-flex;
  @extend .flex-gap--small;

  label {
    white-space: nowrap;
  }

  .control {
    @extend .flex-item-1;
    @extend .px--small;
    @extend .py--small;
  }
}


// table 
.flex-table-container {
  overflow-x: auto;
  overflow-y: auto;
}

.flex-table-container>table {
  @extend .w-100;
  max-height: 80vh;

  th.mat-header-cell {
    @extend .bg-dark-gray;
    @extend .p-1;
    @extend .fw-400;
    @extend .text-left;
    @extend .text-white;

    &.mat-sort-header {
      cursor: pointer;
    }
  }

  td.mat-cell {
    @extend .p-1;
  }
}

.table-head-border {
  @include abm-border(1px, #d5d5d5);
}

// skip-links
.skip-link {
  @extend .pos-absolute;
  @extend .bg-blue;
  @extend .text-white;
  left: 0.5rem;
  padding: 0.5rem 1.5rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  transform: translateY(-120%);
  transition: transform 325ms ease-in;
}

.skip-link:focus {
  transform: translateY(0);
}

.max-height-40 {
  max-height: 40vh !important;
}

.max-height-100 {
  max-height: 100vh;
}

.scroll-content {
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer !important;
}

//watermark
.watermark {
  @extend .pos-fixed;
  @extend .fs--big;
  top: 50%;
  left: 50%;
  margin-left: -7rem;
}

//panel box
.panel-box {
  border: 1px solid #f0f0f0;
  padding: 1.5rem;
}


// datepicker service request
.inp-datepicker {
  height: 2rem;
  line-height: 2rem !important;
  margin: 0 !important;
  padding: 0 0.5rem !important;
  color: #181f2c !important;
  border: 1px solid #e5e7e8 !important;
  border-radius: 0.25rem;
  box-sizing: border-box !important;
}