.admin-page {
  .users-status {
    display: flex;
    .user-status-section {
      margin-left: auto;
      strong {
        color: #006ce6;
      }
    }
  }
  .admin-search {
    .manage-search {
      width: 90%;
    }
    .addUser-Panel {
      float: right;
      margin-top: 10px;
      .mat-icon {
        position: relative;
        top: 3px;
      }
      .btn-submit {
        height: 31px;
      }
      .btn-name {
        position: relative;
        top: -4px;
      }
    }
  }
  .manageUserTable {
    height: 100vh;
    overflow: auto;
    .badge-create {
      border-radius: 50px;
      height: 2.5em;
      width: 2.5em;
      background: #e2eefc;
      .badge-name {
        font-size: 10pt;
        text-align: center;
        color: #006ce6;
        position: relative;
        top: 9px;
        left: 9px;
        right: 9px;
        bottom: 9px;
      }
    }
    table {
      td {
        .mat-icon {
          cursor: pointer;
        }
      }
    }
  }
  .no-results {
    text-align: center;
    font-size: 11pt;
    padding: 15px;
  }
}
.autocomplete-items {
  width: 60%;
  position: absolute;
  border: 1px solid #d4d4d4;
  z-index: 99;
  max-height: 350px;
  overflow-y: scroll;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  //border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
.manage-menu-item {
  max-height: 40px;
  .mat-icon {
    font-size: 19pt !important;
    color: #006ce5 !important;
  }
  span {
    font-size: 10pt !important;
  }
}
.add-user-content,
.edit-user {
  .item {
    .add-user-form {
      width: 100%;
    }
    .add-user-form:nth-child(1) {
      margin-right: 1rem;
    }
    .add-user-form-phone:nth-child(1) {
      margin-right: 10px !important;
    }
    .add-user-form-phone {
      width: 23%;
    }
  }
  .add-user-feedback {
    text-align: center;
  }
  .edit-admin-checkbox {
    margin-left: 1em;
  }
}
.manageuser {
  .mangeuser-error {
    text-align: center;
    margin-right: 5rem;
  }
}
.duplicate-sr-dialog .mat-dialog-container {
  padding-bottom: 0px;
}

.duplicate-cases table,
.admin-page table,
.client-setting-page table {
  width: 100%;
}
.mat-sort-header-arrow,
[dir="rtl"] .mat-sort-header-position-before .mat-sort-header-arrow {
  margin: 0 0 0 6px;
  line-height: 214px;
  color: white;
}
.client-setting-page input[readonly],
.client-setting-page input[-moz-read-only] {
  background-color: lightgrey !important;
  cursor: default !important;
  color: rgba(24, 31, 44, 0.5) !important;
  border-color: rgba(229, 231, 232, 0.75) !important;
}

.duplicate-cases table th,
.admin-page table th,
.client-setting-page table th {
  background-color: #556471;
  color: #f4f4f4;
  font-weight: 400;
  padding: 0.66em 1em 0.66em 0.66em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.admin-page table tr:nth-child(even) {
  background-color: #f4f4f4;
}

/* style for geneal update page  start*/
.general-update-panel {
  .expansion-panel {
    border: 1px solid #f0f0f0;
    .expansion-content {
      .highlighted-container {
        display: flex;
        .list-features {
          flex: 1;
          p ul li {
            list-style-type: disc !important;
          }
        }
        .text-editor {
          flex: 1;
          max-height: 300px;
          button {
            float: right;
            margin-top: 5px;
          }
        }
      }
      .list-content {
        span {
          font-size: 11pt;
        }
        mat-icon {
          cursor: pointer;
          margin-left: 5px;
        }
      }
      .add-more-section {
        margin: 15px 0px 0px 6px;
        .add-more-button {
          float: right !important;
        }
        .add-more-form-section {
          width: 80%;
          float: left;
          .release-form-field {
            width: 100%;
            margin-left: 15px;
            input {
              width: 100%;
            }
            mat-icon {
              cursor: pointer;
              float: right;
            }
          }
        }
      }
    }
  }
}
/* style for geneal update page end */

// style for update tow destination

.approval-page {
  display: flex;
  margin-right: 5px;
  .section-container:nth-child(2) {
    margin-left: 14px;
  }
  .section-container {
    flex: 1;
    .item {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      .item-title {
        width: 40%;
      }
      .form-field {
        width: 60%;
        input,
        select {
          width: 100%;
        }
      }
    }
  }
  .approval-container {
    .item {
      .form-field {
        text-align: center !important;
      }
    }
  }
}

.duplicate-cases table th .mat-sort-header-sorted .mat-sort-header-arrow {
  color: rgb(222, 224, 226);
}

.duplicate-cases table tr.detail-row {
  height: 0;
}

.duplicate-cases table tr.mat-row {
  /* background-color: #f7f7f7; */
}

.duplicate-cases table tr.element-row:not(.expanded-row):hover {
  background: #f5f5f5;
}

.duplicate-cases table tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.duplicate-cases table tr.element-row td {
  border-bottom-width: 0;
  color: #000;
  /* border: 1px solid #d5d5d5; */
  padding: 0.66em;
  text-align: left;
  max-width: 30rem;
}

.duplicate-cases table td.mat-cell {
  padding-right: 20px;
}

.duplicate-cases table .element-detail {
  overflow: hidden;
  display: flex;
}

.duplicate-cases .filter-duplicate {
  font-size: 14px;
  width: 100%;
}

.duplicate-cases .filter-duplicate:hover .tooltiptext,
.admin-page .admin-search:hover .tooltiptext {
  visibility: visible;
  width: 28vw;
  left: 1px;
  right: 68.8rem;
  bottom: 1.5rem;
}
.admin-page .admin-search .tooltiptext::after {
  margin-left: 2rem;
  margin-top: -14px;
}

.duplicate-cases .filter-duplicate .tooltiptext::after,
.admin-page .admin-search .tooltiptext::after {
  top: 48px;
  left: 13rem;
}

.duplicate-cases .title-group {
  margin-bottom: 0px;
}

.duplicate-cases .title-group h3 {
  margin-bottom: 0px;
}

.duplicate-cases .title-group .feedback {
  margin: 0px 0px 5px 20px;
  color: #006ce6;
}
.duplicate-cases .title-group .feedBackSub {
  margin: 0px 0px 5px 0px;
}

.duplicate-cases .table-container {
  /* height: 382px; */
  /* overflow: auto;
    max-height:100%; */
  max-height: 80vh;
  /* overflow: scroll; */
  overflow-x: auto;
  overflow-y: auto;
}

.duplicate-cases .table-container .expand-view {
  /* height: 20rem; */
  height: 100vh;
  width: 100%;
  /* max-height: 20rem; */
  overflow-y: scroll;
}

.duplicate-cases .table-container .expand-view .card:nth-child(1) {
  margin: 1px 1px 15px 1px;
}

.duplicate-cases .table-container .expand-view .card:last-child {
  margin-bottom: 1px;
}

.duplicate-cases .table-container .expand-view .card {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 15px;
}

.duplicate-cases .table-container .expand-arrow {
  cursor: pointer;
}

.duplicate-cases .table-container .expand-view .card .item-title {
  width: 11rem;
  text-align: right;
  /* margin-top:0px; */
}

.duplicate-cases .table-container .expand-view .card .item-data {
  width: 15rem;
  text-align: left;
  margin-top: 3px;
}

.addFacilityPacContainer {
  position: fixed !important;
}

.showError {
  margin: 0.5rem 0;
}

.showSuccessMsg {
  margin: 0.5rem 0;
  color: blue;
}

.sr-error {
  color: red;
  text-align: right;
}

.data-table {
  outline: none;
  max-height: 500px;
  overflow: scroll !important;
}

.data-table table {
  width: auto;
  max-width: 99.999%;
}

.dialog-search-location .data-table table {
  width: 99.999%;
}

.is-mat-date-picker .mat-datepicker-toggle {
  top: 0rem;
  left: 6rem;
}

.page-create-request .is-mat-date-picker .mat-datepicker-toggle {
  left: 90%;
}

.data-table table td a:hover {
  color: #006ce6;
  text-decoration: underline;
  cursor: pointer;
}

.page-case-details .case-cancel .item {
  position: relative;
}

.page-case-details .btn-invoice {
  margin-right: 0.3rem;
}

.page-case-details .btn-invoice {
  margin-right: 0.3rem;
}

.dialog-search-location button,
.screen-sr-new-dialog mat-dialog-actions button,
.screen-lg-dialog mat-dialog-actions button,
.page-create-request .btn-submit {
  margin-right: 0.3rem;
}

.dialog-attachments {
  width: auto;
}

.dialog-attachments .files-attached .attachment-controls {
  width: 100%;
  display: flex;
  align-items: center;
}

.dialog-attachments .files-attached .attachment-filename {
  margin: 0 1rem;
}

.dialog-attachments .files-attached .btn-remove {
  margin: 0 5.5rem;
}

.confirmation-block {
  width: 100%;
}

.screen-dialog,
.screen-sr-dialog,
.screen-sr-new-dialog,
.screen-lg-dialog {
  max-width: 100% !important;
  /* overflow: hidden; */
  /* width:100% !important */
}

.screen-sr-dialog .mat-dialog-container {
  position: relative;
  max-width: none;
  /* width: 90rem; */
  width: 95vw;
  background-color: #fff;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  color: #000;
  /* height: 40rem;
    max-height: 40rem; */
  height: 95vh;
}

.screen-sr-new-dialog .mat-dialog-container {
  width: 50rem;
  position: relative;
}

.screen-sr-new-dialog .mat-dialog-content {
  width: 100%;
}

.screen-lg-dialog .mat-dialog-container {
  width: 85rem;
  position: relative;
}

.screen-lg-dialog .mat-dialog-content {
  width: 100%;
}

.screen-dialog .mat-dialog-container {
  position: relative;
  max-width: none;
  width: 50rem;
  background-color: #fff;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  color: #000;
}

.screen-dialog .mat-dialog-actions,
.screen-lg-dialog .mat-dialog-actions,
.screen-sr-new-dialog .mat-dialog-actions {
  justify-content: flex-end;
  margin: 1rem 0;
}

.screen-dialog .btn-complete-upload {
  margin-right: 0.3rem;
}

.screen-dialog .close-dialog {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  outline: none;
  color: #0257b7;
  cursor: pointer;
}

.screen-dialog .mat-dialog-content,
.screen-sr-dialog .mat-dialog-content {
  padding: 0 1rem 0 0;
  margin: 0;
  position: relative;
  max-height: 100%;
}
.screen-md-dialog{
  position: relative;
  max-width: none;
  width: 30rem;
  background-color: #fff;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  color: #000;
}

.screen-dialog .mat-dialog-content .dialog-exit,
.screen-sr-dialog .mat-dialog-content .dialog-exit,
.screen-sr-new-dialog .mat-dialog-content .dialog-exit
.screen-lg-dialog .mat-dialog-content .dialog-exit {
  position: absolute;
  top: 0;
  right: 0;
}

.dialog-search-location {
  width: 100%;
}

input[type="file"] {
  display: none;
}

input[type="email"],
input[type="number"] {
  font-size: 1rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0;
  padding: 0 0.5rem;
  color: #181f2c;
  border: 1px solid #e5e7e8;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.dialog-add input[type="email"],
.dialog-add input[type="number"] {
  width: calc(100%);
}

.case-cancel {
  position: relative;
}

.showCancelCaseMsg {
  position: absolute;
  /* text-align: right; */
  top: 3rem;
  right: 0.8rem;
  font-weight: bold;
}

.feedback {
  color: #eb0000;
}

.nav-search .btn-search-submit {
  top: 0.5rem;
}

.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  border: 1px solid;
  bottom: 2.5rem;
  left: 18rem;
  right: 0.8rem;
  position: absolute;
  z-index: 1;
}
.tooltiptext-readonly {
  visibility: hidden;
  width: auto;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  border: 1px solid;
  bottom: 2.5rem;
  left: 18rem;
  right: 0.8rem;
  position: absolute;
  z-index: 1;
  max-width: 23rem;
}
.tooltiptext-readonly::after {
  content: " ";
  position: absolute;
  top: 6.5rem;
  left: 10rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 3.9rem;
  left: 10rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltiptext-approvaltext::after {
  content: " ";
  position: absolute;
  top: 4rem;
  left: 23rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltiptext-approvaltext {
  visibility: hidden;
  width: auto;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  border: 1px solid;
  bottom: 15.5rem;
  left: 16rem;
  right: 0.8rem;
  position: absolute;
  z-index: 1;
}

.mat-tooltip {
  font-size: 10pt !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}

mat-slide-toggle.cdk-keyboard-focused>.mat-slide-toggle-label>.mat-slide-toggle-bar,
mat-slide-toggle.cdk-keyboard-focused>.mat-slide-toggle-label>.mat-slide-toggle-bar>.mat-slide-toggle-thumb-container>.mat-slide-toggle-thumb {
  outline: none;
  box-shadow: 0 0 0 1px black;
}

.case-cancel .item-input:hover .tooltiptext,
.case-cancel .item-input:hover .tooltiptext-readonly,
.page-create-request .item-input:hover .tooltiptext-approvaltext {
  visibility: visible;
}

.nav-search input {
  padding-left: 2.5rem;
}

.nav-search .btn-search-submit {
  top: 0;
}

.nav-search .btn-search-submit img {
  padding-top: 0.4rem;
}

.nav-search .btn-search-submit .mat-progress-spinner {
  top: 0.2rem;
}

.btn-search-submit.disabled,
.btn-search-submit:disabled {
  background-color: #e5e7e8;
  color: #7a7a7a;
  cursor: default;
}

.screen-dialog .mat-dialog-content,
.screen-sr-dialog .mat-dialog-content {
  overflow: initial;
}

.quickSearchErrorInline {
  position: absolute;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  text-align: right;
}

.page-case-details .case-history {
 // margin-top: 3rem;
  /* added to have space around while the cancel case msg appears*/
}

html {
  overflow-y: scroll;
  /*fix to remove the width on scroll being added*/
}

.page-create-request .block-5 .has-radio {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

footer {
  padding: 1.5rem;
}

.page-reports-search .card.time-period {
  position: relative;
}

.filterBtn {
  position: absolute;
  right: 0.8rem;
  top: 4.5rem;
}

.filterBtn .date-range-item {
  display: flex;
  flex-direction: row-reverse;
}

.page-create-request input[readonly],
.page-create-request input[-moz-read-only],
.disableSelect {
  background-color: lightgrey !important;
  cursor: default !important;
  color: rgba(24, 31, 44, 0.5) !important;
  border-color: rgba(229, 231, 232, 0.75) !important;
}

.page-reports-search .row > .card.scrolling {
  position: relative;
  // overflow: hidden;
}

[hidden] {
  display: none !important;
}

.page-reports-search .date-range .item-input input {
  width: 9rem;
}

.page-reports-search .date-range {
  margin-top: 2rem;
  align-items: center;
}

.page-reports-search .timePeriodCls .item-input select {
  width: 9rem;
}

.is-required:after {
  content: " *";
  color: #eb0000;
}

a:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: rgba(94, 92, 92, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.header-account .account-link a:hover {
  text-decoration: none;
  cursor: none;
}

.page-reports-search .mat-pseudo-checkbox {
  background-color: transparent;
  width: 17px !important;
  height: 17px !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.54);
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
}

.page-reports-search .mat-pseudo-checkbox-checked {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.54);
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
}

.page-reports-search .mat-pseudo-checkbox::after {
  color: #006ce6;
}

.page-reports-search .mat-pseudo-checkbox-checked::after {
  top: 1px;
  left: 0px;
}

.page-reports-search .mat-selection-list {
  margin-top: 8px !important;
  padding: 0 !important;
  max-height: 160px;
  overflow-y: scroll;
}

.page-reports-search .mat-list-text {
  font-size: 1rem;
  padding: 0 0.5rem !important;
}

.page-reports-search .mat-list-option {
  height: 27px !important;
  line-height: 13px !important;
}

.page-reports-search .mat-list-item-content-reverse {
  padding: 0 !important;
  flex-direction: row !important;
}

select {
  padding-right: 0;
}

.page-error {
  padding-bottom: 4rem;
}

.page-error .description {
  margin: 0.5rem 0 0 0;
}

.page-error .feedback {
  margin-left: 1rem;
}

.mat-datepicker-content-touch {
  overflow: hidden !important;
}

.mat-calendar {
  // width: 50vh !important;
  // height: 55vh !important;
}

.is-mat-date-picker input::-ms-clear {
  display: none;
}

.timeoutMsg {
  color: #eb0000;
  margin: 0 0.6rem;
}

.clientName {
  color: #eb0000;
  font-size: 18pt;
  font-weight: 600;
  margin-left: 2rem;
}
//adding margin space for Training Link 
.training-link{
  margin: 10px 0px 10px 20px;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
}

.client-logo-popup {
  width: 8rem;
  aspect-ratio: 3 / 2;
  object-fit: contain;
  mix-blend-mode: darken;
}

.btn-need-attention-refresh {
  font-size: 1rem;
  font-weight: 500;
  height: 2.5rem;
  line-height: 2rem;
  padding: 0 1em;
  border-radius: 0.25rem;
  border: 0 none transparent;
  background-color: #006ce6;
  color: white;
  min-width: 8rem;

  &:disabled {
    background-color: #006ce480;
  }
}


.datatable-mh-55 {
  max-height: 46vh;
}

.mh-35 {
  max-height: 35vh;
}

.min-mw-20 {
  min-width: 20vw!important;
}

.max-width-80 {
  max-width: 80% !important;
}

.odd-row {
  background-color: #f4f4f4 !important;
}

.even-row {
  background-color: #ffffff !important;
}

.add-scroll {
  overflow: auto;
}

// placeholder contrast
.mat-form-field-appearance-legacy .mat-form-field-label, .mat-select-value,
.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: #757575!important;
}

.mat-paginator-icon {
  fill: #757575!important;
}

.mat-expansion-panel-header:focus-visible {
  background-color: #959595 !important;

  // &>span>mat-panel-title>h3 {
    // color: #ffffff !important;
  // }
}

.mat-expansion-panel-body {
  padding-top: 0.5rem !important;
}